<template>
  <div>
    <base-loader :isLoading="isLoading"></base-loader>
    <div class="text-center mar40bot">
      <h1>Questionnaire</h1>
      <h2>Please complete the form below.</h2>
      <p>
        Please note your answers will be populated into your document directly
        on completion of this form.
      </p>
      <strong class="secondary--text"
        >DO NOT CLICK ON ASSEMBLE AT THE BOTTOM OF THE PAGE UNTIL YOU HAVE
        ANSWERED ALL THE QUESTIONS IN THE VARIOUS TABS RUNNING ACROSS THE TOP OF
        THE QUESTIONNAIRE!</strong
      >
    </div>
    <div class="question-iframe__wrapper">
      <div class="d-flex" v-if="iframe" v-html="iframe"></div>
    </div>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import SectionBanner from "@/components/Frontend/SectionBanner";
export default {
  components: {
    SectionBanner,
  },
  data() {
    return {
      isLoading: false,
      iframe: "",
      purchases: [],
      product: {},
      section: {
        BannerSmall: true,
        Title: "Questionnaire",
        Subtitle: "Please complete the form below.",
        Description:
          "Please note your answers will be populated into your document directly on completion of this form.",
        img: require("@/assets/img/background.jpg"),
      },
    };
  },
  apollo: {
    order: {
      query: gql`
        query Order($id: ID!) {
          order(id: $id) {
            id
            totalAmount
            products
            createdAt
          }
        }
      `,
      variables() {
        const self = this;
        return {
          id: self.$route.params.order_id,
        };
      },
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response) {
        const self = this;
        self.product = self.order.products.filter((item) => {
          return item.id == self.$route.params.product_id;
        })[0];

        const params = this.objectToQueryString({
          order: this.$route.params.order_id,
          product: this.$route.params.product_id,
        });
        self.iframe = self.product.digitalItem.replace(
          `"></iframe>`,
          `&${params}"></iframe>`
        );
        self.iframe = self.iframe.replace(
          `returnURL=`,
          "returnURL=https://nla-graphql-server.herokuapp.com/xpressDoxReturnURL"
        );
      },
    },
  },
  methods: {
    objectToQueryString(obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
  },
};
</script>
<style lang="scss" scoped>
.question-iframe__wrapper {
  width: 890px;
  margin: 0 auto;
}
// .question-iframe__wrapper {
//   position: relative;
//   overflow: hidden;
// }

// #xpressDoxInterview {
//   position: absolute !important;
//   top: 0 !important;
//   left: 0 !important;
//   width: 100% !important;
//   height: 1000px !important;
//   border: 0 !important;
// }
</style>
